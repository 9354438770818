import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAnalytics from 'vue-analytics';

import App from './App.vue';

import LevelOne from './views/LevelOne';
import LevelTwo from './views/LevelTwo';
import LevelThree from './views/LevelThree';
import LoginForm from './views/LoginForm';

import "@/style.scss";

// ROUTES
const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'LevelOne',
      component: LevelOne
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginForm
    },
    {
      path: '/:slug',
      name: 'LevelTwo',
      component: LevelTwo
    },
    {
      path: '/:slug/:slugChild',
      name: 'LevelThree',
      component: LevelThree
    },
  ]
});

[Vuex, VueRouter].forEach((x) => Vue.use(x));
Vue.use(VueAnalytics, {
  id: 'UA-45502670-61',
  router
});

// STORE
const store = new Vuex.Store({
  state: {
    categories: [],
    premioCategories: [],
    loggedIn: false,
  },
  getters: {
    categories: state => {
      return state.categories;
    },

    premioCategories: state => {
      return state.premioCategories;
    },
    loggedIn: state => {
      return state.loggedIn;
    }
  },
  mutations: {
    SET_ITEMS (state, categories) {
      state.categories = categories
    },
    SET_SEARCH_CATEGORIES (state, _categories) {
      state.premioCategories = _categories
    },
    SET_LOGIN(state, loggedIn) {
      localStorage.setItem('loggedIn', loggedIn);
      state.loggedIn = loggedIn;
    },
  },
  actions: {
    async loadCategories({ commit }) {
      const response = await axios.get('https://service-tool.gdhs.marlinc.com/api/trees/premio_service');
      commit('SET_ITEMS', response.data.categories);
      const premioCategories = [];
      const categories = response.data.categories;
      for (let i = 0; i < categories.length; i++) {
        categories[i].path = categories[i].slug;
        premioCategories.push(categories[i]);
        let children = categories[i].children;
        for (let j = 0; j < children.length; j++) {
          children[j].path = categories[i].slug + '/' + children[j].slug;
          premioCategories.push(children[j]);
          let subChildren = children[j].children;
          for (let k = 0; k < subChildren.length; k++) {
            subChildren[k].path = children[j].path;
            premioCategories.push(subChildren[k])
          }
        }
      }
      commit('SET_SEARCH_CATEGORIES', premioCategories);
    }
  }
})

if (localStorage.getItem('loggedIn')) {
  store.commit('SET_LOGIN', true);
}

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.state.loggedIn) {
    return next({ name: 'Login' });
  } else {
    return next();
  }
})

new Vue({
  el: '#app',
  store,
  created() {
    this.$store.dispatch('loadCategories');
  },
  render: h => h(App),
  router
})

export default router;

<template>
  <div id="app">
    <header class="header">
      <nav class="navbar navbar-expand-lg navbar-dark">
        <div class="container">
          <router-link :to="'/'" class="navbar-brand">
            <img :src="require('@/assets/logo.svg')" alt="" />
          </router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <h1>Autoservice</h1>
              </li>
            </ul>
            <form v-if="!loginPage" class="d-flex">
              <input
                v-model="searchQuery"
                placeholder="Suchen"
                class="search form-control me-2"
                type="search"
                aria-label="Search"
              />
            </form>
          </div>
        </div>
      </nav>
    </header>
    <main>
    <div class="search-results">
      <div v-for="result of resultQuery" :key="result.id" class="results">
        <router-link :to="'/' + result.path">
          {{ result.name }}
        </router-link>
      </div>
    </div>
    <router-view></router-view>
    </main>
    <footer class="footer">
      <p class="text-center">
        Wenden Sie sich bei Fragen an Ihren Serviceberater oder Meister.
      </p>
    </footer>
  </div>
</template>

<script>
let premioCategories = [];

export default {
  name: "App",
  data() {
    return {
      searchQuery: null,
      premioCategories
    };
  },
  computed: {
    loginPage() {
      return this.$route.name === 'Login'
    },
    resultQuery() {
      if (this.searchQuery) {
        return this.$store.getters.premioCategories
          .filter((element) => {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every(
                (s) =>
                  element.name.toLowerCase().includes(s) ||
                  (element.description &&
                    element.description.toLowerCase().includes(s))
              );
          })
          .slice(0, 10);
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
body {
  .header {
    background: $primary;
    color: $white;
    padding: 1rem 0 1rem 0;

    img {
      width: 10rem;

      @include media-breakpoint-down(md) {
        width: 8rem;
      }
    }
  }

  .search-results {
    text-align: center;
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  main {
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 7rem;

    @include media-breakpoint-between(sm, lg) {
      background-image: url(assets/images/premio_autoservice-tool.jpg);
      height: calc(100vh - 233px);
    }

    @include media-breakpoint-up(lg) {
      background-image: url(assets/images/premio_autoservice-tool.jpg);
    }

    @include media-breakpoint-up(xl) {
      height: calc(100vh - 233px);
    }
  }

  .breadcrumb-nav {
    width: 100%;
  }

  .breadcrumb {
    margin-top: 1.5rem;
    justify-content: center;

    a {
      text-decoration: none;
      color: $primary;
    }
  }

  h2 {
    padding: 2rem;
    text-align: center;
    font-weight: $font-weight-bold;
    width: 100%;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }

  .level-one,
  .level-two,
  .level-three {
    h2 {
      padding: 1rem 0;
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    gap: 1rem;

    a {
      text-decoration: none;
    }
  }

  .btn {
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: auto;
    border: none;
    text-align: left;

    @include media-breakpoint-up(lg) {
      width: 60%;
    }

    @include media-breakpoint-up(md) {
      width: 60%;
    }

    &:focus,
    &:hover,
    &:active {
      background-color: $primary;
      color: $secondary;

      span:after {
        background-image: url(assets/images/arrow-right-yellow.svg);
      }
    }
  }

  .btn-primary {
    background-color: $gray-200;
    color: $primary;
  }

  .btn-inverted {
    background: $primary;
    color: $white;
  }

  .buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 75%;
    margin: 3rem auto 0;
    gap: 2rem;
  }

  .btn-lvl {
    span {
      position: relative;

      &:after {
        content: "";
        background-image: url(assets/images/arrow-right.svg);
        background-repeat: no-repeat;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        position: absolute;
        top: -0.15rem;
        right: -1.5rem;
      }
    }
  }

  .accordion-item {
    width: 75%;
    margin: auto;

    .accordion-body {
      background: $gray-200;
      color: $primary;
      padding: 2rem 2.25rem;
    }
  }
  .no-description {
    padding: 1rem 1.25rem;
  }

  .footer {
    background-color: $primary;
    color: $white;
    position: fixed;
    bottom: 0;
    width: 100%;

    p {
      padding: 1rem 0.25rem 1rem 0.25rem;
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <div class="level-two container-lg">
    <nav aria-label="breadcrumb" class="breadcrumb-nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="'/'">Startseite</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ category.name }}
        </li>
      </ol>
    </nav>
    <h2>{{ category.name }}</h2>
    <div class="links">
      <router-link
        v-for="(child, index) in category.children"
        :key="index"
        class="btn btn-primary btn-lvl"
        :to="'/' + category.slug + '/' + child.slug"
      >
        <span>{{ child.name }}</span>
      </router-link>
    </div>
    <div class="buttons">
      <router-link class="btn btn-inverted" :to="'/'">Zurück</router-link>
      <router-link 
      v-if="nextCategory"
      :to="'/' + nextCategory.slug" class="btn btn-inverted"
        >Weiter zum nächsten Schritt</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "LevelTwo",
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    category() {
      const urlSlug = this.$route.params.slug;
      const category = this.categories.find(
        (element) => element.slug === urlSlug
      );
      return category || {};
    },
    nextCategory() {
      const urlSlug = this.$route.params.slug;
      const categoryIndex = this.categories.findIndex(
        (element) => element.slug === urlSlug
      );
      const category = this.categories[categoryIndex+1];
      return category
    }
  },
};
</script>
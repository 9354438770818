<template>
  <div>
    <h2>Premio-Autoservice in 7&nbsp;Schritten</h2>
    <div class="level-one container-lg">
      <!-- <router-link to="">
      </router-link> -->
      <div class="links">
      <router-link
        v-for="category, index in categories"
        :key="index"
        class="btn btn-primary btn-lvl"
        :to="'/'+category.slug"
      >
        <span>
          <img
          :src="require(`@/assets/images/icons/${category.slug}.svg`)" alt="icon" class="icon">
          {{ category.name }}
        </span>
      </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LevelOne",
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
  }
};
</script>
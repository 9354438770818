<template>
  <main>
    <form class="login-form">
      <div class="login">
        <input
          type="password"
          v-model="password"
          @input="clear"
          placeholder="Passwort"
          class="form-control me-2"
        />
        <button class="button btn btn-inverted" @click="submit">Login</button>
      </div>
      <div v-if="!this.valid" class="alert alert-danger" role="alert" id="passwordAlert">
        Dieses Passwort ist nicht korrekt.
      </div>
    </form>
  </main>
</template>

<script>
const premioPassword = "4c0d52a0197ac4f3f335e3d6a2306cb0c48a65359cd405610c07c39ca6fcc2e1";

export default {
  name: "LoginForm",
  data() {
    return {
      password: "",
      valid: true
    }
  },

  methods: {
    submit(e) {
      this.digestMessage(this.password).then(value => {
        if (value === premioPassword) {
          this.$store.commit("SET_LOGIN", true);
          this.$router.push("/");
        } else {
          this.valid = false;
        }
      })
      e.preventDefault();
    },
    async digestMessage(message) {
      const msgUint8 = new TextEncoder().encode(message);
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    },
    clear() {
      this.valid = true;
    }
  }
};
</script>

<style lang="scss">
.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.login {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
</style>
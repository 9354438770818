<template>
  <div class="level-three container-xl">
    <nav aria-label="breadcrumb" class="breadcrumb-nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="'/'">Startseite</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/' + category.slug">{{
            category.name
          }}</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ child.name }}
        </li>
      </ol>
    </nav>
    <h2>{{ child.name }}</h2>
    <div class="accordion" id="accordion">
      <div
        v-for="(childTwo, index) in child.children"
        :key="index"
        class="accordion-item"
      >
        <h3 v-if="childTwo.description" class="accordion-header" id="heading">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#' + childTwo.slug"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            {{ childTwo.name }}
          </button>
        </h3>
        <div class="no-description" v-if="!childTwo.description">
          {{ childTwo.name }}
        </div>
        <div
          :id="childTwo.slug"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordion"
        >
          <div class="accordion-body">
            {{ childTwo.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <router-link class="btn btn-inverted" :to="'/' + category.slug"
        >Zurück</router-link
      >
      <router-link
        v-if="nextChildCategory"
        :to="'/' + category.slug + '/' + nextChildCategory.slug"
        class="btn btn-inverted"
        >Weiter zum nächsten Schritt</router-link
      >
      <router-link
        v-if="!nextChildCategory && nextCategory"
        :to="'/' + nextCategory.slug"
        class="btn btn-inverted"
        >Weiter zum nächsten Schritt</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "LevelThree",
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    category() {
      const urlSlug = this.$route.params.slug;
      const category = this.categories.find(
        (element) => element.slug === urlSlug
      );
      return category || {};
    },
    child() {
      const childSlug = this.$route.params.slugChild;
      const child = this.category.children.find(
        (element) => element.slug === childSlug
      );
      return child || {};
    },
    nextCategory() {
      const urlSlug = this.$route.params.slug;
      const categoryIndex = this.categories.findIndex(
        (element) => element.slug === urlSlug
      );
      const category = this.categories[categoryIndex + 1];
      return category;
    },
    nextChildCategory() {
      const childSlug = this.$route.params.slugChild;
      const childIndex = this.category.children.findIndex(
        (element) => element.slug === childSlug
      );
      const childCategory = this.category.children[childIndex + 1];
      return childCategory;
    },
  },
};
</script>

<style lang="scss">
.accordion-button:not(.collapsed) {
  background-color: $primary !important;
  color: $secondary !important;
}

.accordion-button:not(.collapsed):after {
  background-image: url(../assets/images/arrow-up.svg) !important;
  transform: rotate(-180deg);
}
</style>